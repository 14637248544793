<template lang="pug">
  .cancellation-fee-form
    ArrowBack(@back="handleClickBack")
    .title
      span {{ $t("company_system.basic_settings.cancellation_fees.details") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.cancellation_fees.code')")
        .form-row-field
          BFormInput(
            type="text"
            name="code"
            v-model="cancellationFee.code"
            :placeholder="$t('company_system.basic_settings.cancellation_fees.code')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.cancellation_fees.name')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field
          BFormInput(
            type="text"
            name="name"
            :class="{ 'is-invalid': $v.cancellationFee.name.$error }"
            v-model="cancellationFee.name"
            :placeholder="$t('company_system.basic_settings.cancellation_fees.name')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.cancellation_fees.fee_type')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field
          .taxes.flex-row
            .taxes-radio.flex-row
              AppRadioButton(
                :value="cancellationFee.fee_type === basicFeeType"
                @change="setFeeType(basicFeeType)"
              )
              .label
                span {{ $t(`company_system.basic_settings.cancellation_fees.basic_fee`) }}
            .taxes-radio.flex-row
              AppRadioButton(
                :value="cancellationFee.fee_type === totalFeeType"
                @change="setFeeType(totalFeeType)"
              )
              .label
                span {{ $t(`company_system.basic_settings.cancellation_fees.total_charge`) }}
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.cancellation_fees.cancellation_fee')"
          )
        .form-row-field.cancellation-fees
          .flex-row
            .left-column
              .label
                FormFieldLabel(
                  :title="$t('company_system.basic_settings.cancellation_fees.no_show')"
                  required
                  :required-label="$t('company_system.req')"
                )
            .right-column.flex-row
              AppNumberInput(
                :class="{ 'is-invalid': $v.cancellationFee.fees.no_show.$error }"
                name="same-time"
                :value="cancellationFee.fees.no_show"
                @input="handleFeesInput('no_show', $event)"
              )
              .label
                span
                  | %
              .action-column
          .flex-row
            .left-column
              .label
                FormFieldLabel(
                  :title="$t('company_system.basic_settings.cancellation_fees.same_day')"
                  required
                  :required-label="$t('company_system.req')"
                )
            .right-column.flex-row
              AppNumberInput(
                :class="{ 'is-invalid': $v.cancellationFee.fees.same_day.$error }"
                name="same-day"
                :value="cancellationFee.fees.same_day"
                @input="handleFeesInput('same_day', $event)"
              )
              .label
                span
                  | %
              .action-column
                AppIconButton.button-add(
                  icon="plus-circle"
                  @click="addDayBeforeItem(-1)"
                )
          template(v-for="(dayItem, index) in $v.cancellationFee.fees.days_before.$each.$iter")
            .flex-row(:key="index")
              .left-column
                .flex-row
                  .label.left
                    span {{ $t(`company_system.basic_settings.cancellation_fees.days_from`, { from: dayItem.from.$model }) }}
                  AppNumberInput(
                    :class="{ 'is-invalid': dayItem.to.$error }"
                    name="until-day"
                    :value="dayItem.to.$model"
                    @input="handleDayBeforeItemDayInput(index, Number($event))"
                  )
                  .label.right
                    span {{ $t(`company_system.basic_settings.cancellation_fees.days_to`) }}
              .right-column.flex-row
                AppNumberInput(
                  :class="{ 'is-invalid': dayItem.value.$error }"
                  name="value"
                  :min="0"
                  :value="dayItem.value.$model"
                  @input="handleDayBeforeItemValueInput(index, Number($event))"
                )
                .label
                  span
                    | %
                .action-column
                  AppIconButton.button-add(
                    icon="plus-circle"
                    @click="addDayBeforeItem(Number(index))"
                  )
                  AppIconButton.button-remove(
                    icon="plus-circle"
                    @click="removeDayBeforeItem(Number(index))"
                  )
          .flex-row
            .form-row.max-fee-row
              .form-row-label
                FormFieldLabel(:title="$t('company_system.basic_settings.cancellation_fees.max_fee')")
                AppTooltip(
                  useAppIcon
                  :title="$t('company_system.basic_settings.cancellation_fees.max_fee_tooltip')"
                  placement="right"
                  icon="exclamationCircleAlt"
                )
              .form-row-field.fee.flex-row
                AppNumberInput(
                  use-delimiter
                  name="max-fee"
                  :min="0"
                  :max="999999"
                  v-model="cancellationFee.max_fee"
                )
                .label
                  span {{ $t('company_system.currency') }}
      .form-row.explanation-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.cancellation_fees.explanation')")
        .form-row-field.explanation
          textarea(
            :rows="2"
            :maxlength="1024"
            name="explanation"
            v-model="cancellationFee.description"
            :class="{ 'invalid': $v.cancellationFee.description.$error }"
            :placeholder="$t('company_system.basic_settings.cancellation_fees.explanation')"
          )
          span.text-length(
            :class="{ 'invalid': !$v.cancellationFee.description.maxLength }"
          )
            | {{ explanationLengthLabel }}
    FormActionBar(
      :delete-tooltip="deleteTooltip"
      :delete-enabled="isEdit && !cancellationFee.unremovable"
      @delete="removeCancellationFee"
      @save="handleSave"
    )
</template>

<script>
  const EXPLANATION_CAPTION_MAX_LENGTH = 128

  // misc
  import { cloneDeep, isEqual } from "lodash-es"
  import { defaultCancellationFeeObject } from "./helpers"

  // store modules
  import cancellationFeesModule from "@/config/store/company_system/basic_settings/cancellation_fees"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"
  import withCompanySystemFormMethods from "@/mixins/company_system/withFormMethods"

  const cancellationFeesMixin = withStoreModule(cancellationFeesModule, {
    name: "cancellationFees",
    readers: {
      cancellationFeeItem: "item"
    },
    actions: {
      fetchCancellationFee: "FETCH_ITEM",
      createCancellationFee: "CREATE_ITEM",
      updateCancellationFee: "UPDATE_ITEM",
      deleteCancellationFee: "DELETE_ITEM"
    },
    mutations: { setDirty: "SET_DIRTY" }
  })

  const validationsMixin = withValidations(({ required, integer, minValue, maxValue, maxLength }) => ({
    cancellationFee: {
      name: { required },
      fee_type: { required },
      description: {
        maxLength: maxLength(EXPLANATION_CAPTION_MAX_LENGTH)
      },
      fees: {
        same_day: {
          required,
          integer,
          minValue: minValue(0),
          maxValue: maxValue(100)
        },
        no_show: {
          required,
          integer,
          minValue: minValue(0),
          maxValue: maxValue(100)
        },
        days_before: {
          $each: {
            from: {
              required,
              integer,
              minValue: minValue(1),
              maxValue: maxValue(100)
            },
            to: {
              required,
              integer,
              minValue: minValue(1),
              maxValue: maxValue(100),
              greaterThanCheck: function(value, self) {
                const daysBefore = this.cancellationFee.fees.days_before
                const index = daysBefore.indexOf(self)
                if (index > 0) {
                  const { to = 0 } = daysBefore[index - 1]
                  return value > to
                }

                return true
              }
            },
            value: {
              required,
              integer,
              minValue: minValue(1),
              maxValue: maxValue(100)
            }
          }
        }
      }
    }
  }))

  export default {
    components: {
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack"),
      FormActionBar: () => import("../../FormActionBar"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    mixins: [cancellationFeesMixin, validationsMixin, withScrollTop, withCompanySystemFormMethods],

    data() {
      return {
        cancellationFee: defaultCancellationFeeObject(),
        initialCancellationFee: {},
        basicFeeType: "basic",
        totalFeeType: "total"
      }
    },

    props: {
      cancellationFeeId: {
        type: Number,
        required: false
      }
    },

    mounted() {
      if (this.isEdit) {
        this.fetchCancellationFee(this.cancellationFeeId).then(() => {
          this.cancellationFee = cloneDeep(this.cancellationFeeItem)
          this.initialCancellationFee = cloneDeep(this.cancellationFeeItem)
        })
      } else {
        this.initialCancellationFee = defaultCancellationFeeObject()
      }
    },

    methods: {
      handleFeesInput(field, value) {
        this.cancellationFee.fees[field] = Number(value)
      },

      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      setFeeType(feeType) {
        this.cancellationFee.fee_type = feeType
      },

      removeCancellationFee() {
        this.$confirm({
          title: this.$t("company_system.basic_settings.delete_cancellation_fee_confirmation"),
          resolve: {
            handler: () => {
              this.deleteCancellationFee(this.cancellationFee.id).then(() => {
                this.$emit("back")
              })
            }
          }
        })
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.cancellationFee = cloneDeep(this.cancellationFeeItem)
          this.initialCancellationFee = cloneDeep(this.cancellationFeeItem)
        })
      },

      async sendRequest() {
        if (this.isEdit) {
          await this.updateCancellationFee(this.cancellationFee)
        } else {
          await this.createCancellationFee(this.cancellationFee)
        }
      },

      buildDaysBefore() {
        return { from: null, to: null, value: null }
      },

      updateFromValues() {
        this.cancellationFee.fees.days_before.forEach((dayItem, index) => {
          if (index < 1) {
            dayItem.from = 1
          } else {
            const { to } = this.cancellationFee.fees.days_before[index - 1]
            dayItem.from = to + 1
          }
        })
      },

      addDayBeforeItem(index) {
        if (index < 0) {
          this.unshiftDayBeforeItem()
        } else if (index === this.cancellationFee.fees.days_before.length - 1) {
          this.pushDayBeforeItem()
        } else {
          this.insertDayBeforeItem(index + 1)
        }
        this.updateFromValues()
      },

      unshiftDayBeforeItem() {
        this.cancellationFee.fees.days_before.unshift(this.buildDaysBefore())
      },

      pushDayBeforeItem() {
        this.cancellationFee.fees.days_before.push(this.buildDaysBefore())
      },

      insertDayBeforeItem(index) {
        this.cancellationFee.fees.days_before.splice(index, 0, this.buildDaysBefore())
      },

      removeDayBeforeItem(index) {
        this.cancellationFee.fees.days_before.splice(index, 1)
        this.updateFromValues()
      },

      handleDayBeforeItemDayInput(index, to) {
        const dayBeforeItem = this.cancellationFee.fees.days_before[index]
        this.cancellationFee.fees.days_before.splice(index, 1, { ...dayBeforeItem, to })
        this.updateFromValues()
      },

      handleDayBeforeItemValueInput(index, value) {
        const dayBeforeItem = this.cancellationFee.fees.days_before[index]
        this.cancellationFee.fees.days_before.splice(index, 1, { ...dayBeforeItem, value })
      }
    },

    computed: {
      isEdit() {
        return !!(this.cancellationFeeId || this.cancellationFee.id)
      },

      deleteTooltip() {
        return this.cancellationFee.unremovable ? this.$t("company_system.relation_tooltips.has_plans") : undefined
      },

      hasUnsavedChanges() {
        return !isEqual(this.cancellationFee, this.initialCancellationFee)
      },

      explanationLength() {
        return this.cancellationFee.description?.length || 0
      },

      explanationLengthLabel({ explanationLength }) {
        return this.textLength(explanationLength, EXPLANATION_CAPTION_MAX_LENGTH)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .cancellation-fee-form
    +styled-inputs

    input
      height: 38px

    margin-bottom: 20px

    .check
      +icon-button($default-purple)
      margin-top: 6px
      margin-left: 6px

      &.disabled
        border: none

    .flex-row
      display: flex
      justify-content: left

    .arrow-back
      cursor: pointer
      margin-left: 5px
      margin-top: 10px

      ::v-deep
        svg
          path
            fill: $default-gray-medium

      span
        vertical-align: text-top
        margin-left: 5px
        color: $default-purple
        font-size: 0.9rem

    .title
      margin-top: 20px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &.explanation-row
          margin-top: 10px

        &.max-fee-row
          margin-top: 10px
          margin-left: 0px

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

          .app-tooltip
            display: inline

            ::v-deep
              svg
                vertical-align: middle
                width: 20px
                height: 20px

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          &.explanation
            display: flex
            flex-direction: column

            textarea
              width: 100%
              margin-bottom: 5px
              border: 1px solid $default-purple-light
              border-radius: 5px
              padding-left: 5px
              font-size: 16px
              line-height: 1.5

              &.invalid
                +default-invalid-input

              &::placeholder
                color: $default-gray-medium

          &.cancellation-fees
            margin-top: 10px
            padding-left: 10px
            max-width: 400px

            .flex-row
              margin-bottom: 5px

              .left-column
                width: 90%
                padding-right: 10px

                .label
                  font-size: 13px
                  padding: 6px 0

                  &.left
                    padding-right: 10px

                    span
                      display: block
                      width: 38px

                  &.right
                    padding-left: 10px

                ::v-deep
                  .app-number-input
                    width: 20%
                    height: 32px
                    text-align: center

                    &.is-invalid
                      input
                        +default-invalid-input

                    input
                      height: 100%
                      width: 100%
                input
                  width: 20%
                  height: 32px
                  text-align: center

              .right-column
                min-width: 155px

                ::v-deep
                  .app-number-input
                    width: 31%
                    height: 32px
                    text-align: center

                    &.is-invalid
                      input
                        +default-invalid-input

                    input
                      height: 100%
                      width: 100%
                input
                  width: 31%
                  height: 32px
                  text-align: center

                .label
                  font-size: 13px
                  padding: 6px

              .action-column
                min-width: 25px
                color: $default-purple
                text-align: center
                margin-top: 5px

                .button-add
                  +icon-button($default-purple)

                .button-remove
                  transform: rotate(45deg)
                  +icon-button($default-red)

          .taxes
            margin-left: 10px

            &-radio
              margin-right: 10px

              .app-radio-button
                padding-top: 2px

              .label
                margin-left: 5px

          .datepicker
            width: 100%
            padding-right: 0

            &.is-invalid
              ::v-deep
                input
                  +default-invalid-input

            ::v-deep
              .mx-input-wrapper
                &:hover
                  .mx-icon-calendar
                    display: initial

              .mx-input
                padding-left: 30px
                padding-right: 10px
                border-color: #ccc
              .mx-icon-calendar
                left: 8px
              .mx-datepicker-popup
                left: 0 !important

          ::v-deep
            input
              &.invalid
                +default-invalid-input

          .text-length
            font-size: 12px
            color: $default-gray-medium

            &.invalid
              color: $default-red

          .app-select
            padding: 0

          &.fee
            ::v-deep
              .app-number-input
                height: 38px
                input
                  text-align: right
                  height: 100%
                  width: 90px

            .label
              padding: 7px
              font-size: 15px
</style>
